import React from "react";
// import "../../styles/styles.scss";

const LeaderboardTabs = ({ activeTab, setActiveTab, setActiveRecord}) => {

  return (
    <div className="leader-tabs">

      <div className="d-flex tabs">
        <button
          className={`tab ${activeTab === 'Fellows' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('Fellows');
            setActiveRecord(null);
          }}>
          All Fellows
        </button>
        <button
          className={`tab ${activeTab === 'Peer' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('Peer');
            setActiveRecord(null);
            }}>
          Peer-to-Peer
        </button>
      </div>

      <p className="last-updated">Last Updated: <span>Just Now</span></p>
    </div>
  );
};

export default LeaderboardTabs;
