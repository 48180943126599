/** @format */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import { decrypt } from "utils/secure";


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const accessToken = decrypt(window.localStorage.getItem("3MTAAT"));

    headers.set("Content-Type", "application/json");
    if (accessToken) {
      headers.set("Authorization", `${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // window.localStorage.removeItem("3MTAAT");
    // window.localStorage.removeItem("3MTAUSER");
    // window.location.replace("/login");
  }
  return result;
};

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    API_TAGS.FETCH_PROVIDER_BROADCASTS,
    API_TAGS.FETCH_FELLOW_NOTIFICATIONS,
    API_TAGS.FETCH_COMMUNITY_RESOURCES,
    API_TAGS.FETCH_LEARNING_CONTENTS
  ],
  endpoints: (builder) => ({
    getFellowNotifications: builder.query({
      query: (query) =>
        `broadcasts/recieved${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_FELLOW_NOTIFICATIONS],
    }),

    getProviderBroadcasts: builder.query({
      query: (query) =>
        `broadcasts/messages${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_PROVIDER_BROADCASTS],
    }),

    updateFellowNotifications: builder.mutation({
      query: (body) => ({
        url: `broadcasts/messages/batch/read`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_FELLOW_NOTIFICATIONS],
      invalidatesTags: [API_TAGS.FETCH_FELLOW_NOTIFICATIONS],
    }),

    deleteProviderBroadcast: builder.mutation({
      query: (ID) => ({
        url: `broadcasts/messages/${ID}`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_BROADCAST],
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_BROADCASTS],
    }),

    createNewBroadcast: builder.mutation({
      query: (body) => ({
        url: `broadcasts/messages`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_BROADCAST],
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_BROADCASTS],
    }),

    getCommunityResource: builder.query({
      query: (query) =>
        `admin/community/online/course${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_COMMUNITY_RESOURCES],
    }),
    createCommunityResource: builder.mutation({
      query: (body) => ({
        url: `admin/community/online/course`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_COMMUNITY_RESOURCE],
      invalidatesTags: [API_TAGS.FETCH_COMMUNITY_RESOURCES],
    }),
    updateCommunityResource: builder.mutation({
      query: (body) => ({
        url: `admin/community/online/course/detail/${body?.id}`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_COMMUNITY_RESOURCE],
      invalidatesTags: [API_TAGS.FETCH_COMMUNITY_RESOURCES],
    }),

    createCourseModule: builder.mutation({
      query: (body) => ({
        url: `courses/modules`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_COMMUNITY_RESOURCE],
      invalidatesTags: [API_TAGS.FETCH_COMMUNITY_RESOURCES],
    }),
    updateCourseModule: builder.mutation({
      query: (body) => ({
        url: `courses/modules/${body?.id}`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_COMMUNITY_RESOURCE],
      invalidatesTags: [API_TAGS.FETCH_COMMUNITY_RESOURCES],
    }),
    getCourseModule: builder.query({
      query: (query) =>
        `courses/modules${query.length > 0 ? `?${query}` : ""}`,
    }),
    getSingleCourseModule: builder.query({
      query: (query) =>
        `courses/modules?page=1&page_size=120&course_ids=${query}`,
    }),
    


    getLearningContent: builder.query({
      query: (query) =>
        `admin/online/learning/content/course${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_LEARNING_CONTENTS],
    }),
    createLearningContent: builder.mutation({
      query: (body) => ({
        url: `admin/online/learning/content/course`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_LEARNING_CONTENT],
      invalidatesTags: [API_TAGS.FETCH_LEARNING_CONTENTS],
    }),
    updateLearningContent: builder.mutation({
      query: (body) => ({
        url: `admin/online/learning/content/course/detail/${body?.id}`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_LEARNING_CONTENT],
      invalidatesTags: [API_TAGS.FETCH_LEARNING_CONTENTS],
    }),
    

    getAllCourses: builder.query({
      query: (query) =>
        `courses/all${query.length > 0 ? `?${query}` : ""}`,
    }),
    createCourse: builder.mutation({
      query: (body) => ({
        url: `courses/all`,
        method: "POST",
        body,
      }),
    }),
    updateCourse: builder.mutation({
      query: (body) => ({
        url: `courses/detail/${body?.id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `courses/detail/${id}`,
        method: "DELETE",
      }),
    }),


    getAllJobs: builder.query({
      query: (query) =>
        `admin/job/page/links`,
    }),
    createJob: builder.mutation({
      query: (body) => ({
        url: `admin/job/page/links`,
        method: "POST",
        body,
      }),
    }),
    updateJob: builder.mutation({
      query: (body) => ({
        url: `admin/job/page/links/detail/${body?.id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `admin/job/page/links/detail/${id}`,
        method: "DELETE",
      }),
    }),
    getAllAssesments: builder.query({
      query: (query) =>
        `courses/assesssments${query.length > 0 ? `?${query}` : ""}`,
    }),
    createAssesment: builder.mutation({
      query: (body) => ({
        url: `courses/assesssments`,
        method: "POST",
        body,
      }),
    }),
    updateAssesment: builder.mutation({
      query: (body) => ({
        url: `courses/assesssments/${body?.id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteAssesment: builder.mutation({
      query: (body) => ({
        url: `courses/assesssments/${body?.id}`,
        method: "DELETE",
      }),
    }),

    getAllAssesmentSubmission: builder.query({
      query: (query) =>
        `courses/submitted/assesssments/by/fellow/admin${query.length > 0 ? `?${query}` : ""}`,
    }),
  }),

  
});

export const {
  useGetFellowNotificationsQuery,
  useGetProviderBroadcastsQuery,
  useUpdateFellowNotificationsMutation,
  useDeleteProviderBroadcastMutation,
  useCreateNewBroadcastMutation,

  useGetCommunityResourceQuery,
  useCreateCommunityResourceMutation,
  useUpdateCommunityResourceMutation,

  useGetLearningContentQuery,
  useCreateLearningContentMutation,
  useUpdateLearningContentMutation,

  useGetAllCoursesQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,

  useGetAllJobsQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,

  useCreateCourseModuleMutation,
  useUpdateCourseModuleMutation,
  useGetCourseModuleQuery,
  useGetSingleCourseModuleQuery,

  useCreateAssesmentMutation,
  useGetAllAssesmentsQuery,
  useUpdateAssesmentMutation,
  useDeleteAssesmentMutation,

  useGetAllAssesmentSubmissionQuery
} = notificationApi;

