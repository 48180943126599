/** @format */
import React from "react";
import { ReactComponent as TrophyIcon } from "images/svg/trophy.svg";
import { ReactComponent as FastIcon } from "images/svg/fast.svg";
import { ReactComponent as KnowledgeIcon } from "images/svg/knowledge.svg";
// import { ReactComponent as LockIcon } from "images/svg/lock.svg";
// import "../../styles/styles.scss";
import Educat from "images/pro.png";
import { ReactComponent as ProfileIcon } from "images/svg/profile.svg";



const LeaderUserTracker = ({ activeRecord }) => {
  const {course_name, darey_score, first_name, last_name, gender, residence, lga, current_program, rank  } = activeRecord || {};


  return (
    <div className="leader-user-tracker">
      {activeRecord ? (
        <>
          <div className="profile-header">
            <figure className="profile-image">
              <ProfileIcon />
              
            </figure>

            <h2 className="text-center">{`${first_name} ${last_name}`}</h2>
            <p className="text-center">{course_name}</p>
            <div className="profile-stats">
              <div className="svg-icon">
                <TrophyIcon />
              </div>
              <span className="profile-rank">{Math.round(darey_score)}</span>
            </div>
          </div>

          <div className="leader-badges">
            <div className="leader-badge">
              <FastIcon />
              <p>Fast Learner</p>
            </div>

            <div className="leader-badge">
              <KnowledgeIcon />
              <p>Knowledge Seeker</p>
            </div>
          </div>

          <div className="alc-details">
            <h2>ALC</h2>
            <h4>
              Gender: <span>{gender}</span>
            </h4>
            <h4>
              Current Program: <span>{current_program}</span>
            </h4>
            <h4>
              Residence: <span>{residence}</span>
            </h4>
            <h4>
              LGA : <span>{lga}</span>
            </h4>

            <h4>
              Position: <span>{rank}</span>
            </h4>
          </div>
        </>
      ) : (
        <div className="empty-profile">
        <figure>
          <img src={Educat} alt="Educat" />
        </figure>
          <h2>No Profile Selected</h2>
          <p>Click on any profile to see detailed view </p>
        </div>
      )}
    </div>
  );
};

export default LeaderUserTracker;
