/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import {
  Nigerian_States,
  LocalGov,
  SkillLevel,
} from "utils/constant.data";
import { debounce } from "lodash";
import {
  FellowOptions,
  GenderOptions,
  LearningProgressOptions,
  ProgramOptions,
  DeeptechFellowStatusOptions
} from "utils/strings";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { useGetAllCoursesQuery } from "store/services/notification";
import axios_instance from "store/constant/axiosInstance";

export const FilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
  restoreFellows,
  clearFilter,
}) => {
  const [selectedStatus, setStatus] = useState([]);
  const [selectedGender, setGender] = useState([]);
  const [selectedLearning, setLearning] = useState([]);
  const [selectedAge, setAge] = useState([]);
  const [selectedStates, setStates] = useState([]);
  const [selectedLGAs, setLGAs] = useState([]);
  const [selectedLevel, setLevel] = useState([]);
  const [selectedCourse, setCourses] = useState([]);
  const [selectedProvider, setProvider] = useState(null);
  const [selectedCohorts, setCohorts] = useState(null);

  const defaultProgram = ProgramOptions.find((option) => option.value === "3mtt");
  const [selectedProgram, setProgram] = useState(defaultProgram);

  const { data, isLoading: loadingCohorts, isFetching:fetchingCohorts } = useGetCohortsQuery(`program=${selectedProgram?.value}`);

  const {data:courses, isLoading:loadingCourses, isFetching:fetchingCourses} = useGetAllCoursesQuery(`program=${selectedProgram?.value}`)

  useEffect(() => {
    setStatus([]);
    setGender([]);
    setAge([]);
    setLevel([]);
    setStates([]);
    setCohorts([]);
    setLGAs([]);
    setProvider([]);
    setLearning([]);
    setProgram("3mtt");
  }, [clearFilter]);

  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    axios_instance
      .get(`admin/providers?status=Selected&search=${inputValue}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.results;
        results?.length > 0
          ? callback(
              results.map((i) => {
                return {
                  label: i.name,
                  value: i.id,
                };
              })
            )
          : callback([]);
      })
      .catch((error) => {
        toast.error("❌ Error seraching for provider");
        callback([]);
      });
  }, 3000);

  const handleFilter = () => {
    let gender = 0;
    let training_progress = 0;

    if (typeof selectedGender === "object" && !Array.isArray(selectedGender)) {
      gender = selectedGender?.value?.length;
    }
    //  else if (
    //   typeof selectedLearning === "object" &&
    //   !Array.isArray(selectedLearning)
    // ) {
    //   training_progress = selectedLearning?.value?.length;
    // }
    else {
      gender = selectedGender.length;
      training_progress = selectedLearning.length;
    }

    if (
      selectedStatus.length === 0 &&
      selectedAge.length === 0 &&
      selectedLevel.length === 0 &&
      gender === 0 &&
      selectedStates.length === 0 &&
      selectedLGAs.length === 0 &&
      selectedCourse.length === 0 &&
      selectedProvider.length === 0 &&
      selectedCohorts.length === 0 &&
      selectedLearning.length === 0 &&
      training_progress === 0 && 
      !selectedProgram
    ) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        age: selectedAge.length > 0 ? selectedAge : [],
        gender: selectedGender?.value ? selectedGender?.value : "",
        cohort_id: selectedCohorts?.value ? selectedCohorts?.value : "",
        proficiency_level: selectedLevel.length > 0 ? selectedLevel?.map(item => item.value) : [],
        status: selectedStatus.length > 0 ? selectedStatus?.map(item => item.value) : [],
        selectedStates: selectedStates.length > 0 ? selectedStates?.map(item => item.value) : [],
        selectedLGAs: selectedLGAs.length > 0 ? selectedLGAs?.map(item => item.value) : [],
        selectedCourse: selectedCourse.length > 0 ? selectedCourse?.map(item => item.value) : [],
        provider_id: selectedProvider?.value ? selectedProvider?.value : "",
        training_progress: selectedLearning.length > 0 ? 
        selectedLearning.map(item => item.value) : [],
        program: selectedProgram ? selectedProgram?.value : "",
      });

    }
  };

  const getLGData = () => {
    const LGs = [];
    selectedStates.forEach((state) => {
      LocalGov?.forEach((val) => {
        if (val?.state?.toLowerCase() === state.value?.toLowerCase()) {
          LGs.push(...val?.lgas);
        }
      });
    });

    return LGs.length > 0
      ? LGs.map((state) => ({
          value: state,
          label: state,
        }))
      : [];
  };

  const StateOptions = Nigerian_States.map((state) => ({
    value: state,
    label: state,
  }));

  const CohortOptions =
    data?.data?.cohorts?.length > 0
      ? data?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  const CourseOptions = courses?.data?.courses?.length > 0 ? courses?.data?.courses?.map((course)=> ({
    value: course?.id,
    label: course?.title
  })) : [];

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter Fellows</h5>
            {/* <p>ddd{JSON.stringify(selectedCohorts)}</p> */}
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
              <Col md={12}>
                  <p className="p-1 m-0">Filter by Program</p>
                  <div className="form-group mb-3">
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedProgram}
                      onChange={setProgram}
                      options={ProgramOptions}
                      styles={Style}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <p className="p-1 m-0">Filter with Status</p>
                  <div className="form-group mb-3">
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedStatus}
                      onChange={setStatus}
                      options={selectedProgram?.value === "deeptech" ? DeeptechFellowStatusOptions : FellowOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <p className="p-1 m-0">Filter with Provider</p>
                  <div className="form-group mb-3">
                    <AsyncSelect
                      cacheOptions
                      loadOptions={(inputValue, callback) =>
                        debouncedLoadOptions(inputValue, callback)
                      }
                      onChange={setProvider}
                      value={selectedProvider}
                      placeholder={<div>Type to search</div>}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      // isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <p className="p-1 m-0">Filter by Learning Progress</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLearning}
                      onChange={setLearning}
                      options={LearningProgressOptions}
                      styles={Style}
                      isMulti
                    />
                  </div>
                </Col>{" "}
                <Col md={12}>
                  <p className="p-1 m-0">Filter with Gender</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedGender}
                      onChange={setGender}
                      options={GenderOptions}
                      styles={Style}
                    />
                  </div>
                </Col>
                {!fetchingCourses && !loadingCourses && CourseOptions?.length > 0 && (
                  <Col md={12}>
                    <p className="p-1 m-0">Filter by Course</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCourse}
                        onChange={setCourses}
                        options={CourseOptions}
                        styles={Style}
                        isMulti
                      />
                    </div>
                  </Col>
                )}
                {/* {
                  CourseOptions.length > 0 && (
                    <Col md={12}>
                  <p className="p-1 m-0">Filter with Course</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedCourse}
                      onChange={setCourses}
                      options={CourseOptions}
                      styles={Style}
                      isMulti
                    />
                  </div>
                </Col>
                  )
                } */}
                {/* <Col md={12}>
                  <p className="p-1 m-0">Filter with Age</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedAge}
                      onChange={setAge}
                      options={AgeOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                */}
                <Col md={12}>
                  <p className="p-1 m-0">Filter with Skill Level</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLevel}
                      onChange={setLevel}
                      options={SkillLevel}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <p className="p-1 m-0">Filter by State of Residence</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedStates}
                      onChange={setStates}
                      options={StateOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
                {!fetchingCohorts && !loadingCohorts && CohortOptions?.length > 0 && (
                  <Col md={12}>
                    <p className="p-1 m-0">Filter by Cohort</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCohorts}
                        onChange={setCohorts}
                        options={CohortOptions}
                        styles={Style}
                      />
                    </div>
                  </Col>
                )}
                <Col md={12}>
                  <p className="p-1 m-0">Filter by LGAs</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLGAs}
                      onChange={setLGAs}
                      options={getLGData()}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setStatus([]);
                  setGender([]);
                  setAge([]);
                  setLevel([]);
                  setProvider([]);
                  setProgram(null);
                  // restoreFellows();
                }}
              >
                Clear Filter
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Filtering ...." : "Filter Fellows"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
