/** @format */

import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useBulkMigrateToDeeptechMutation } from "store/services/adminAPI";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";


export const DeeptechModal = ({
  setAction,
  action,
  selectedIds,
  setSelectedIds,
  setSelectedFellowIds,
}) => {
  const [bulkMigrateToDeeptech, { isLoading }] = useBulkMigrateToDeeptechMutation();



  const handleSubmit = async () => {
    await bulkMigrateToDeeptech({
      program: "deeptech",
      fellows: [...selectedIds],
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedIds([]);
        setSelectedFellowIds([]);
        setAction("");
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <Modal
      show={action === "assign_deeptech" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Migrate Fellow(s) to Deeptech </h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="card  course-dedails-bx">
            <div className="card-header border-0 pb-0">
                  This action changes the current program of the selected fellows to Deeptech.
            </div>
          </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              type="button"
              disabled={isLoading || selectedIds?.length < 1}
              onClick={() => {
                handleSubmit();
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                `Migrate`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
