import React, { useState } from "react";
import AdminLayout from "views/admin/Admin";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import CohortsTable from "views/admin/table/Cohorts/CohortTable";
import usePermissionCheck from "hooks/useCheck";
import { Page } from "utils/constant.data";



const AdminCohorts = () => {
  usePermissionCheck(Page.COHORTS)
  const [filter, setFilter] = useState("page=1");

  const {
    data,
    isLoading,
    isFetching
  } = useGetCohortsQuery(filter);



  return (
    <AdminLayout>
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
            <div>
          <CohortsTable
            cohorts={data?.data?.cohorts}
            isLoading={isLoading}
            isFetching={isFetching}
            metadata={data?.data?.metadata}
            filter ={filter}
            setFilter={setFilter}
          />
            </div>

        )}
      </div>
    </AdminLayout>
  );
};
export default AdminCohorts;
