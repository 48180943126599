/** @format */

import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { useBulkAssignCohortMutation } from "store/services/adminAPI";
import { replaceUnderscoresWithSpaces, ProgramOptions } from "utils/strings";
import Swal from "sweetalert2";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { Style } from "utils/select";

// useBulkAssignCohortMutation

export const CohortModal = ({
  setAction,
  action,
  selectedIds,
  setSelectedIds,
  setSelectedFellowIds,
}) => {
  const [selectedCohorts, setCohorts] = useState(null);
  const [bulkAssignCohort, { isLoading }] = useBulkAssignCohortMutation();
  const defaultProgram = ProgramOptions.find((option) => option.value === "3mtt");
  const [selectedProgram, setProgram] = useState(defaultProgram);

  const {
    data,
    isLoading:loadingCohorts,
    isFetching:fetchingCohorts
  } = useGetCohortsQuery(`program=${selectedProgram?.value}`);



  const handleProgramChange = (newProgram) => {
    setProgram(newProgram);
  };


  const handleSubmit = async () => {
    await bulkAssignCohort({
      program: selectedProgram?.value,
      cohort_id: selectedCohorts?.value,
      fellows: [...selectedIds],
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedIds([]);
        setSelectedFellowIds([]);
        setAction("");
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const CohortOptions =
    data?.data?.cohorts?.length > 0
      ? data?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  return (
    <Modal
      show={action === "assign_cohort" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Assign Fellow(s) to Cohort</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="card  course-dedails-bx">
              <div className="card-header border-0 pb-0">
                <label>Select Program</label>
              </div>
              <div className="card-body pt-0">
                <div className="description">
                  <Select
                  value={selectedProgram}
                  onChange={handleProgramChange}
                  options={ProgramOptions}
                  styles={Style}
                  />
                </div>
              </div>
              {(loadingCohorts || fetchingCohorts) ? (
                <p>Loading cohorts...</p>
                  ) : (
                    data?.data?.cohorts?.length > 0 ? (
                      <>
                        <div className="card-header border-0 pb-0 mt-4">
                          <label>Select Cohort</label>
                        </div>
                        <div className="card-body pt-0">
                          <div className="description">
                            <Select
                              value={selectedCohorts}
                              onChange={setCohorts}
                              options={CohortOptions}
                              styles={Style}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>No available cohorts</p>
                    )
                  )}



            </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              disabled={
                selectedCohorts && selectedIds?.length > 0 ? false : true
              }
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                `Assign`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};