import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces, capitalize, FellowOptions, ProviderOptions } from "utils/strings";
import { toast } from "react-toastify";
import { recipients } from "utils/constant.data";
import { useUpdateBroadcastCarouselMutation } from "store/services/adminAPI";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { useGetAllCoursesQuery } from "store/services/notification";
import { Style } from "utils/select";
import Select from "react-select";
import { extractErrorMessage } from "utils/strings";

const UpdateCarousel = ({ 
  showUpdateCarousel, 
  setShowUpdateCarousel, 
  title, 
  message, 
  link, 
  id, 
  recipient_type, 
  target_statuses,
  target_cohorts,
  target_courses
}) => {
  const [updateCarouselAPI, { isLoading }] = useUpdateBroadcastCarouselMutation();
  const [submitted, setSubmitted] = useState(false);

  const [selectedStatus, setStatus] = useState(() => 
    target_statuses 
      ? target_statuses.split(',').map(status => ({
          value: status.trim(), 
          label: capitalize(status.trim())
        }))
      : []
  );


  const [selectedCourses, setSelectedCourses] = useState(() => {
    if (!target_courses) return [];
    const coursesArray = Array.isArray(target_courses) ? target_courses : [target_courses];
    return coursesArray.map(courseId => ({
      value: courseId,
      label: ''
    }));
  });

  const [selectedCohorts, setSelectedCohorts] = useState(() => {
    if (!target_cohorts) return [];
    const cohortsArray = Array.isArray(target_cohorts) ? target_cohorts : [target_cohorts];
    return cohortsArray.map(cohortId => ({
      value: cohortId,
      label: ''
    }));
  });

  const { data: cohorts, isLoading: loadingCohorts } = useGetCohortsQuery("");
  const { data: coursesList, isLoading: loadingCourses } = useGetAllCoursesQuery();

  useEffect(() => {
    if (cohorts?.data?.cohorts && selectedCohorts.length > 0) {
      setSelectedCohorts(prev => 
        prev.map(selected => ({
          ...selected,
          label: `Cohort ${cohorts.data.cohorts.find(c => c.id === selected.value)?.rank || selected.value}`
        }))
      );
    }
  }, [cohorts, selectedCohorts?.length]);


  useEffect(() => {
    if (coursesList?.data?.courses && selectedCourses.length > 0) {
      setSelectedCourses(prev =>
        prev.map(selected => ({
          ...selected,
          label: coursesList?.data?.courses.find(c => c.id === selected.value)?.title || selected.value
        }))
      );
    }
  }, [coursesList, selectedCourses?.length]);

  const CohortOptions = cohorts?.data?.cohorts?.map((cohort) => ({
    value: cohort?.id,
    label: `Cohort ${cohort?.rank}`,
  })) || [];

  const CoursesOptions = coursesList?.data?.courses
    ?.filter((course) => course?.status)
    ?.map((course) => ({
      value: course?.id,
      label: course?.title,
      code: course?.code,
    })) || [];

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitted(true);

    if (selectedStatus.length > 0) {
      setSubmitted(false);
      
      const payload = {
        id,
        title: values.title,
        message: values.message,
        recipient_type: values.recipient_type,
        target_statuses: selectedStatus.map(item => item.value).join(',')
      };

      if (values.link?.trim()) {
        payload.link = values.link.trim();
      }

      if (selectedCohorts.length > 0) {
        payload.target_cohorts = selectedCohorts.map(cohort => cohort.value);
      }

      if (selectedCourses.length > 0) {
        payload.target_courses = selectedCourses.map(course => course.value);
      }

      await updateCarouselAPI(payload)
        .unwrap()
        .then((data) => {
          toast.success(`✔️ ${data?.message} !`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
          });

          resetForm();
          setShowUpdateCarousel(false);
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error);
          Swal.fire({
            title: replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occurred",
            text: formattedError,
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Try Again",
          });
        });
    } else {
      toast.error("❌ Please select relevant status!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      title: title || '',
      message: message || '',
      link: link || '',
      recipient_type: recipient_type || '',
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    // enableReinitialize: true,
  });

  return (
    <Modal className="modal fade" show={showUpdateCarousel}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Update Carousel Broadcast</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowUpdateCarousel(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Title</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="Title"
                    {...formik.getFieldProps("title")}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.title}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Content</strong>
                  </label>
                  <textarea
                    className="w-100 form-control"
                    placeholder="Content"
                    id="message"
                    name="message"
                    {...formik.getFieldProps("message")}
                    rows="3"
                  ></textarea>
                  {formik.touched.message && formik.errors.message ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.message}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Link</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    name="link"
                    placeholder="Link"
                    {...formik.getFieldProps("link")}
                  />
                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.link}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Recipient</strong>
                  </label>
                  <select
                    id="recipient_type"
                    name="recipient_type"
                    className="form-control"
                    {...formik.getFieldProps("recipient_type")}
                  >
                    <option value="">Choose Recipient</option>
                    {recipients.map((type) => (
                      <option key={type} value={type.toUpperCase()}>
                        {capitalize(type)}
                      </option>
                    ))}
                  </select>
                  {formik.touched.recipient_type && formik.errors.recipient_type ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.recipient_type}
                    </div>
                  ) : null}
                </div>
              </div>

              {!loadingCohorts && formik.values.recipient_type === "FELLOW" && (
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Cohorts</strong>
                    </label>
                    <Select
                      value={selectedCohorts}
                      onChange={setSelectedCohorts}
                      options={CohortOptions}
                      styles={Style}
                      isMulti={true}
                    />
                  </div>
                </div>
              )}

              {!loadingCourses && (
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Courses</strong>
                    </label>
                    <Select
                      value={selectedCourses}
                      onChange={setSelectedCourses}
                      options={CoursesOptions}
                      styles={Style}
                      isMulti={true}
                    />
                  </div>
                </div>
              )}

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Status</strong>
                  </label>
                  <Select
                    value={selectedStatus}
                    onChange={setStatus}
                    options={
                      formik.values.recipient_type === "PROVIDER"
                        ? ProviderOptions
                        : formik.values.recipient_type === "FELLOW"
                        ? FellowOptions
                        : []
                    }
                    styles={Style}
                    isMulti
                  />
                  {submitted && selectedStatus.length < 1 ? (
                    <div className="text-danger mt-2 fs-12">
                      Please select relevant status!
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button type="submit" className="submit btn btn-pigment-green">
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Update Carousel`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateCarousel;

const FormSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  message: Yup.string().required("Carousel content is required"),
  recipient_type: Yup.string().required("Recipient type is required"),
  link: Yup.string(),
});