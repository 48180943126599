import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {ProgramOptions } from "utils/strings";
import { Style } from "utils/select";
import Select from "react-select";




export const FilterModal =({
    showFilterModal,
    setShowFilterModal,
    setFilter
}) => {

    const defaultProgram = ProgramOptions.find((option) => option.value === "3mtt");
    const [selectedProgram, setProgram] = useState(defaultProgram);


    const handleFilter = () => {
        setFilter(`program=${selectedProgram?.value}`);
        setShowFilterModal(false);
    };


    return (
        <div>
            <Modal
                show={showFilterModal}
                onHide={() => setShowFilterModal(false)}
                className="modal fade"
                id="create-modal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Filter Cohorts</h5>
                        <Button
                            variant=""
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => { setShowFilterModal(false); }}
                        >
                            <span>×</span>
                        </Button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                <div className="form-group mb-3">
                                        <label>Select program</label>
                                            <Select
                                            value={selectedProgram}
                                            onChange={setProgram}
                                            options={ProgramOptions}
                                            styles={Style}
                                            />
                                    </div>
                                </div>
                                <div className="col-lg-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-primary btn-sl-sm"
                                    type="button"
                                    onClick={()=>{
                                        handleFilter()
                                    }}
                                >
                                    Filter
                                </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}