/** @format */
import React from "react";
import { ReactComponent as BgIcon } from "images/svg/bg.svg";
import { ReactComponent as LeaderboardRankIcon } from "images/svg/leaderboard-rank.svg";
import { ReactComponent as PeerGroupScore } from "images/svg/peer-group-score.svg";

const PeerGroupStats = ({ data }) => {
  return (
    <div className="group-stat-cards">
      <div className="peer-group-card">
        <LeaderboardRankIcon />
        <h3 className="stat-title">Total Number<br/> of Groups</h3>
        <h3 className="stat-count">{data?.total_number_of_peers || "N/A"}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>

      <div className="peer-group-card">
        <PeerGroupScore />
        <h3 className="stat-title">Total Submission <br/>Per Group</h3>
        <h3 className="stat-count">{data?.project_submission_per_group}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      <div className="peer-group-card">
        <PeerGroupScore />
        <h3 className="stat-title">Highest Peer Group <br/>Darey Score</h3>
        <h3 className="stat-count">{Math.round(data?.highest_darey_score || "N/A")}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
    </div>
  );
};

export default PeerGroupStats;
