/** @format */

import React, { useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { ProgramOptions } from "utils/strings";

import { useGetAllCoursesQuery } from "store/services/notification";
import { useGetCohortsQuery } from "store/services/adminAPI";

export const FilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
  restoreFellows,
}) => {
  const [selectedCategory, setCategory] = useState([]);
  const [selectedCohorts, setCohorts] = useState([]);
  const defaultProgram = ProgramOptions.find((option) => option.value === "3mtt");
  const [selectedProgram, setProgram] = useState(defaultProgram);

  const { data: coursesList, isLoading: loadingCourses, isFetching: fetchingCourses } = useGetAllCoursesQuery(`program=${selectedProgram?.value}`);

  const { data: cohortsList, isLoading: loadingCohorts, isFetching:fetchingCohorts } = useGetCohortsQuery(`program=${selectedProgram?.value}`);

  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const handleFilter = () => {
    if (selectedCategory.length === 0 && selectedCohorts.length === 0 && !selectedProgram?.value) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        course_ids: selectedCategory.length > 0 ? selectedCategory : [],
        cohort_ids: selectedCohorts.length > 0 ? selectedCohorts : [],
        program: selectedProgram?.value ? selectedProgram?.value : "",
      });
    }
  };

  const Categories =
    coursesList?.data?.courses
      ?.filter((_) => {
        return _?.status;
      })
      ?.map((role) => ({
        value: role?.id,
        label: role?.title,
        code: role?.code,
      })) ?? [];

  const Cohorts =
    cohortsList?.data?.cohorts?.map((role) => ({
      value: role?.id,
      label: role?.name + " " + role?.rank,
    })) ?? [];

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter Resources</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>

              
              <Col md={12}>
                  <p className="p-1 m-0">Filter by program</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedProgram}
                      onChange={setProgram}
                      options={ProgramOptions}
                      styles={Style}
                      // components={{ ClearIndicator }}
                    />
                  </div>
                </Col>

                {loadingCourses || fetchingCourses ? (
                <p>Loading Courses.....</p>
              ) : (
                <Col md={12}>
                  <p className="p-1 m-0">Filter by course</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedCategory}
                      onChange={setCategory}
                      options={Categories}
                      styles={Style}
                      isMulti
                      components={{ ClearIndicator }}
                    />
                  </div>
                </Col>
              )}
            {loadingCohorts || fetchingCohorts ? (
                <p>Loading Cohorts.....</p>
              ) : (
                <Col md={12}>
                  <p className="p-1 m-0">Filter by cohorts</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedCohorts}
                      onChange={setCohorts}
                      options={Cohorts}
                      styles={Style}
                      isMulti
                      components={{ ClearIndicator }}
                    />
                  </div>
                </Col>
              )}
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setCategory([]);
                  setCohorts([]);
                  restoreFellows();
                }}
              >
                Clear Filter
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Filtering ...." : "Filter Resource"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;