/** @format */

import React, { useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { extractErrorMessage, FellowOptions, DeeptechFellowStatusOptions, ProgramOptions } from "utils/strings";
import axios_instance from "store/constant/axiosInstance";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { useGetAllCoursesQuery } from "store/services/notification";
import * as XLSX from 'xlsx';


export const ImportModal = ({
  showModal,
  setShowImportModal,
  setRetrievedFellows,
}) => {
  const [action, setAction] = useState("change_status");
  const [newStatus, setNewStatus] = useState("");
  const [newCohort, setNewCohort] = useState("");
  const [newProvider, setNewProvider] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [fellowIdentifier, setFellowIdentifier] = useState("");
  const [newCourse, setNewCourse] = useState("");

  const defaultProgram = ProgramOptions.find((option) => option.value === "3mtt");
  const [selectedProgram, setProgram] = useState(defaultProgram);


const debouncedLoadOptions = debounce((inputValue, callback) => {
    axios_instance
      .get(`admin/providers?status=Selected&search=${inputValue}`)
      .then((response) => {
        const results = response?.data?.data?.results;
        results?.length > 0
          ? callback(
              results.map((i) => {
                return {
                  label: i.name,
                  value: i.id,
                };
              })
            )
          : callback([]);
      })
      .catch((error) => {
        toast.error("❌ Error seraching for provider");
        callback([]);
      });
  }, 3000);


  const handleDownload = () => {
    const downloadUrl = process.env.PUBLIC_URL + '/process_from_excel_sample.xlsx';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'process_from_excel_sample.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success(`✔️ Sample file downloaded!`, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const { data: cohortsData, isLoading: loadingCohorts, isFetching:fetchingCohorts } = useGetCohortsQuery(`program=${selectedProgram?.value}`);
  const { data:courses, isLoading: loadingCourses, isFetching: fetchingCourses } = useGetAllCoursesQuery(`program=${selectedProgram?.value}`);


  const CohortOptions =
    cohortsData?.data?.cohorts?.length > 0
      ? cohortsData?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

    const ImportOptions = [
        { value: "retrieve", label: "Retrieve" },
        { value: "change_status", label: "Change Status" },
        { value: "assign_cohort", label: "Assign Cohort" },
        { value: "assign_provider", label: "Assign/Reassign Provider" },
        { value: "export", label: "Export" },
        { value: "match_fellows", label: "Match Fellows" },
        {value: "change_course", label:"Change Course"},
        {value: "migrate_deeptech", label:"Migrate To Deeptech"},
        {value: "migrate_3mtt", label:"Migrate To 3MTT"}
  ];

  const CoursesOptions = courses?.data?.courses?.length > 0
      ? courses?.data?.courses?.map((course) => ({
          value: course?.id,
          label: course?.title
      })) : [];

  const FellowIdentifierOptions = [
    { value: "email", label: "Emails" },
    { value: "fellow_id", label: "Fellow IDs" },
];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv"
    ];

    if (file) {
      const fileType = file.type;
      if (action.value === "match_fellows") {
          if (fileType !== "text/csv") {
              toast.error("❌ Only CSV files are allowed for matching fellows.", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
              });
              setSelectedFile(null);
              return;
          }
      } else {
          if (!allowedFileTypes.includes(fileType) || fileType === "text/csv") {
              toast.error("❌ Only Excel files (XLS, XLSX) are allowed for this action.", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
              });
              setSelectedFile(null);
              return;
          }
      }

      if (file.size > 10 * 1024 * 1024) {
          toast.error("❌ File size exceeds the 10MB limit.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
          });
          setSelectedFile(null);
      } else {
          setSelectedFile(file);
      }
  };
};

  const handleSubmit = async() => {

    if (!selectedFile) {
        toast.error("❌ Please select a file.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
        });
        return;
    }

    if (!action) {
        toast.error("❌ Please select an action.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
        });
        return;
    }

    if (action.value === "export") {

        try {
            setLoading(true);
            const formData = new FormData()
            selectedFile && formData.append("file", selectedFile);
            formData.append("fellow_property", fellowIdentifier.value)

            await axios_instance.post("admin/export/fellows", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
    
            toast.success(`✔️ An email will be sent to you!`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
            setShowImportModal(false);

        } catch (error) {
            const formattedError = extractErrorMessage(error)
            toast.error(`❌ ${formattedError}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
        }
    }

    if (action.value === "retrieve") {
        const reader = new FileReader();
    
        reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result)
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false });
            const columnIndex = jsonData[0].indexOf('Email');

            if (columnIndex === -1) {
                toast.error(`❌ 'Email' column is missing.`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                return;
            }
            
            const emailList = jsonData.slice(1).map(row => row[columnIndex]);    
            if (emailList.length > 100) {
                toast.error(`❌ More than 100 emails provided.`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                return;
            }
    
            try {
                setLoading(true);
    
                const response = await axios_instance.post("admin/bulk/retrieve/fellows/excel", {
                    emails: emailList
                }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                setRetrievedFellows(response?.data?.data);
    
                toast.success(`✔️ Successfully retrieved!`, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                setLoading(false);
                setShowImportModal(false);
            } catch (error) {
                const formattedError = extractErrorMessage(error.response)
                toast.error(`❌ ${formattedError || "Error retrieving fellows"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                setLoading(false);
            }
        };
    
        reader.readAsArrayBuffer(selectedFile);
    }

    if (action.value === "change_status") {

        try {
            setLoading(true);
            const formData = new FormData()
            selectedFile && formData.append("file", selectedFile);
            formData.append("status", newStatus?.value)
            formData.append("program", selectedProgram?.value)

            await axios_instance.post("admin/bulk/update/fellows/status/excel", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
    
            toast.success(`✔️ Fellow Status updated!`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
            setShowImportModal(false);

        } catch (error) {
            const formattedError = extractErrorMessage(error.response)
            toast.error(`❌ ${formattedError}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
        }
    }



    if (action.value === "assign_cohort") {
        try {
            setLoading(true);
            const formData = new FormData()
            selectedFile && formData.append("file", selectedFile);
            formData.append("cohort_id", newCohort?.value)
            formData.append("program", selectedProgram?.value)
            await axios_instance.post("admin/bulk/assign/fellows/to/cohort/excel/email", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
    
            toast.success(`✔️ Fellows cohort assigment in progress!`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
            setShowImportModal(false);

        } catch (error) {
            const formattedError = extractErrorMessage(error.response)
            toast.error(`❌ ${formattedError}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
        }
    }

    if (action.value === "assign_provider") {
        setLoading(true);
        const formData = new FormData();
        if (selectedFile) formData.append("file", selectedFile);
        formData.append("provider_id", newProvider);
        formData.append("program", selectedProgram?.value);
        axios_instance.post("admin/bulk/assign/fellows/to/provider/excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
          setShowImportModal(false);
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error.response);
          toast.error(`❌ ${formattedError}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
        });
      }


      if (action.value === "match_fellows") {

        if (selectedFile.type !== "text/csv") {
          toast.error("❌ Please select a CSV file. Only CSV files are allowed for this action.");
          return;
        }

        try {
            setLoading(true);
            const formData = new FormData()
            selectedFile && formData.append("file", selectedFile);
            formData.append("cohort_id", newCohort.value);
            formData.append("method", "csv")

            await axios_instance.post("admin/match/fellows/to/provider", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
    
            toast.success(`✔️ An email will be sent to you!`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
            setShowImportModal(false);

        } catch (error) {
            const formattedError = extractErrorMessage(error.response)
            toast.error(`❌ ${formattedError}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
        }
    }
      if (action.value === "change_course") {
        setLoading(true);
        const formData = new FormData();
        if (selectedFile) formData.append("file", selectedFile);
        formData.append("course_id", newCourse?.value);
        formData.append("program", selectedProgram?.value);
        axios_instance.post("admin/bulk/assign/fellows/to/course/excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
          setShowImportModal(false);
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error.response);
          toast.error(`❌ ${formattedError}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
        });
      }
      if (action.value === "migrate_deeptech") {
        setLoading(true);
        const formData = new FormData();
        if (selectedFile) formData.append("file", selectedFile);
        axios_instance.post("admin/bulk/migrate/fellow/to/deeptech/excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
          setShowImportModal(false);
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error.response);
          toast.error(`❌ ${formattedError}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
        });
      }
      if (action.value === "migrate_3mtt") {
        setLoading(true);
        const formData = new FormData();
        if (selectedFile) formData.append("file", selectedFile);
        axios_instance.post("admin/bulk/migrate/fellow/to/3mtt/excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
          setShowImportModal(false);
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error.response);
          toast.error(`❌ ${formattedError}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
        });
      }
  }


  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowImportModal(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Process Fellows from file</h5>
            {/* <p>ddd{JSON.stringify(selectedCohorts)}</p> */}
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowImportModal(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                {
                  action.value === "match_fellows" ? (
                    <Col md={12}>
                    <div className="form-group mb-3">
                          <label htmlFor="bulkFellowsFile" className="form-label">
                              Upload CSV File
                          </label>
                          <input
                              className="form-control"
                              type="file"
                              id="bulkFellowsFile"
                              accept=".csv"
                              onChange={handleFileChange}
                          />
                     
                    </div>
                  </Col>

                  ) : (
                    <Col md={12}>
                    <div className="form-group mb-3">
                          <label htmlFor="bulkFellowsFile" className="form-label">
                              Upload Excel File
                          </label>
                          <input
                              className="form-control"
                              type="file"
                              id="bulkFellowsFile"
                              accept=".xls, .xlsx, .csv"
                              onChange={handleFileChange}
                          />
                     
                    </div>
                  </Col>
                  )
                }
               
                <Col md={12}>
                  <p className="p-1 m-0">Choose Action</p>
                  <div className="form-group mb-3">
                    <Select
                      value={action}
                      onChange={setAction}
                      options={ImportOptions}
                      styles={Style}
                    />
                  </div>
                </Col>
                {action.value === "change_status" && (
                  <>
                    <Col md={12}>
                    <p className="p-1 m-0">Select Program</p>
                    <div className="form-group mb-3">
                    <Select
                        value={selectedProgram}
                        onChange={setProgram}
                        options={ProgramOptions}
                        styles={Style}
                    />
                    </div>
                  </Col>

                    <Col md={12}>
                        <p className="p-1 m-0">Set New Status</p>
                        <div className="form-group mb-3">
                        <Select
                            value={newStatus}
                            onChange={setNewStatus}
                            options={selectedProgram?.value === "deeptech" ? DeeptechFellowStatusOptions : FellowOptions}
                            styles={Style}
                        />
                        </div>
                    </Col>
                    </>
                )
                }


                {(action.value === "assign_cohort" || action.value === "match_fellows") && CohortOptions.length > 0 && (
                  <>
                  
                  <Col md={12}>
                        <p className="p-1 m-0">Select Program</p>
                        <div className="form-group mb-3">
                            <Select
                                value={selectedProgram}
                                onChange={setProgram}
                                options={ProgramOptions}
                                styles={Style}
                            />
                        </div>
                    </Col>
                    {
                      fetchingCohorts || loadingCohorts ? ( 
                        <p>Loading cohorts...</p>
                      ) : (
                        <Col md={12}>
                        <p className="p-1 m-0">Set New Cohort</p>
                        <div className="form-group mb-3">
                            <Select
                                value={newCohort}
                                onChange={setNewCohort}
                                options={CohortOptions}
                                styles={Style}
                            />
                        </div>
                    </Col>
                      )
                    }
                    
                    </>
                )}
                
                 {action.value === "assign_provider" && (
                  <>
                  <Col md={12}>
                    <p className="p-1 m-0">Select Program</p>
                    <div className="form-group mb-3">
                    <Select
                        value={selectedProgram}
                        onChange={setProgram}
                        options={ProgramOptions}
                        styles={Style}
                    />
                    </div>
                  </Col>
                  
                    <Col md={12}>
                        <p className="p-1 m-0">Set Provider</p>
                        <div className="form-group mb-3">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={(inputValue, callback) =>
                            debouncedLoadOptions(inputValue, callback)
                            }
                            defaultOptions
                            onChange={(selectedOption) =>
                                setNewProvider(selectedOption?.value)
                            }
                            styles={Style}
                        />
                        </div>
                    </Col>
                    </>
                )
                }

                {action.value === "export" && (
                    <Col md={12}>
                        <p className="p-1 m-0">Fellow Identifier</p>
                        <div className="form-group mb-3">
                        <Select
                            value={fellowIdentifier}
                            onChange={setFellowIdentifier}
                            options={FellowIdentifierOptions}
                            styles={Style}
                        />
                        </div>
                    </Col>
                )
                }
                 {action.value === "change_course" && (
                  <>
                   <Col md={12}>
                        <p className="p-1 m-0">Select Program</p>
                        <div className="form-group mb-3">
                            <Select
                                value={selectedProgram}
                                onChange={setProgram}
                                options={ProgramOptions}
                                styles={Style}
                            />
                        </div>
                    </Col>

                  {/* {
                    fetchingCourses || loadingCourses ? (
                      <p>Loading courses...</p>
                      
                    ) : (
                      <Col md={12}>
                        <p className="p-1 m-0">Set Course</p>
                        <div className="form-group mb-3">
                        <Select
                          value={newCourse}
                            onChange={setNewCourse}
                            options = {CoursesOptions}
                            styles={Style}
                        />
                        </div>
                    </Col>
                    )
                  } */}

                  {
                    (fetchingCourses || loadingCourses) ? (
                      <p>Loading courses...</p>
                    ) : (
                      <Col md={12}>
                        <p className="p-1 m-0">Set Course</p>
                        <div className="form-group mb-3">
                        <Select
                          value={newCourse}
                            onChange={setNewCourse}
                            options = {CoursesOptions}
                            styles={Style}
                        />
                        </div>
                    </Col>
                    )
                  }
                    
                  </>
                )
                }
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <button
              type="button"
              className="btn btn-outline ms-2 "
              onClick={() => {
                handleDownload()
              }}
            >
              Download Sample
            </button>

            <button
              type="button"
                onClick={() => {
                    handleSubmit();
                }}
                disabled={loading}
                className="btn btn-pigment-green ms-2 "
                >
                {loading ? "Processing ...." : "Process File"}
            </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImportModal;
