// export const extractFilterValues = (filter) => {
//     const res = {};
  
//     for (const key of Object.keys(filter)) {
//       if (key === "gender" ||  key === "cohort_id" || key ==="provider_id" || key === "branch_id" || key === "training_progress" || "program" ){
//         res[key] = filter[key];
//       } else {
//         const values = filter[key].map((value) => value?.value);
//         res[key] = values;
//       }
//     }
  
//     return res;
//   };

export const extractFilterValues = (filter) => {
  const res = {};
  
  for (const [key, value] of Object.entries(filter)) {
    // If you intend to filter by passing a key directly, add it to this list
    const directPassKeys = [
      "gender", 
      "page", 
      "cohort_id", 
      "duration", 
      "program", 
      "min_score", 
      "max_score", 
      "category",
      "provider_id", 
      "branch_id", 
      "training_progress"
    ];

    if (directPassKeys.includes(key)) {
      res[key] = value;
    } else  {
      res[key] = value.map((item) => 
        typeof item === 'object' && item !== null ? item.value || item : item
      );
    }
  }
  
  return res;
};
  export const filterArrayByKey = (searchTerm, array) => {
    const regex = new RegExp(searchTerm, "i"); // 'i' flag for case-insensitive search
    return array.filter((item) => regex.test(item.name));
  };