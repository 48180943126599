import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCreateCohortMutation } from "store/services/adminAPI";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces, ProgramOptions } from "utils/strings";
import * as Yup from 'yup'
import { useFormik } from "formik";
import {formatDateToYYYYMMDD} from "utils/date";
import { Style } from "utils/select";
import Select from "react-select";




export const CreateModal =({
    showModal,
    setShowCreateModal
}) => {

    const defaultProgram = ProgramOptions.find((option) => option.value === "3mtt");
    const [selectedProgram, setProgram] = useState(defaultProgram);

    const [createCohort, {isLoading}] = useCreateCohortMutation();

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const cleaned_date = formatDateToYYYYMMDD(values.start_date);
            await createCohort({
                start_date: cleaned_date,
                duration: values.duration,
                program: selectedProgram?.value
            })
            .unwrap();
            toast.success(`✔️ Cohort Created!`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            resetForm()
            setTimeout(() => {
                setShowCreateModal(false);
            }, 1500);
    
        } catch (error) {
            console.error(error);
            Swal.fire({
                title:
                    replaceUnderscoresWithSpaces(error?.data?.code) ||
                    "Error Occurred",
                text: error?.data?.detail,
                iconHtml: `<i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                showCloseButton: true,
                customClass: {
                    icon: "no-border",
                },
                confirmButtonText: "Try Again",
            });
        }
    };

    const formik = useFormik({
        initialValues: {
            duration: "",
            start_date:null
        },
        validationSchema: FormSchema,
        onSubmit: handleSubmit,
        enableReinitialize:true
    })

    return (
        <div>
            <Modal
                show={showModal}
                onHide={() => setShowCreateModal(false)}
                className="modal fade"
                id="create-modal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Create Cohort</h5>
                        <Button
                            variant=""
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => { setShowCreateModal(false); }}
                        >
                            <span>×</span>
                        </Button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                <div className="form-group mb-3">
                                        <label>Select program</label>
                                            <Select
                                            value={selectedProgram}
                                            onChange={setProgram}
                                            options={ProgramOptions}
                                            styles={Style}
                                            />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Duration (in weeks)</label>
                                        <input
                                            type="text"
                                            className="form-control bg-transparent"
                                            placeholder="Duration"
                                            id="duration"
                                            name="duration"
                                            {...formik.getFieldProps("duration")}
                                            pattern="[0-9]*" 
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                        />

                                        {formik.touched.duration && formik.errors.duration ? (
                                            <div className="text-danger mt-2 fs-12">
                                                {formik.errors.duration}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="d-block">Start Date</label>
                                        <DatePicker
                                            selected={formik.values.start_date} 
                                            onChange={(start_date) => formik.setFieldValue('start_date', start_date)} 
                                            className="form-control bg-transparent"
                                            />
                                        {formik.touched.start_date && formik.errors.start_date ? (
                                            <div className="text-danger mt-2 fs-12">
                                                {formik.errors.start_date}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-primary btn-sl-sm "
                                    type="submit"
                                >
                                     {isLoading ? (
                                        <span
                                        role="button"
                                        aria-hidden="true"
                                        className="spinner-border spinner-border-sm ms-2"
                                        ></span>
                                    ) : (
                                        `Create Cohort`
                                    )}
                                </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}


const FormSchema = Yup.object().shape({
    duration: Yup.number().required('Please set a duration for the cohort').positive('Duration must be a positive number'),
    start_date: Yup.string().required('Please set a start date for the cohort')

})