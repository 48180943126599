/** @format */

import React, { useState } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces, capitalize, ProgramOptions, DeeptechFellowStatusOptions } from "utils/strings";
import { toast } from "react-toastify";
import { recipients } from "utils/constant.data";
import { useCreateNewBroadcastMutation } from "store/services/notification";
import { fellowNotificationStatus, providerStatus} from "utils/constant.data";
import { Style } from "utils/select";
import Select from "react-select";
import { useGetCohortsQuery } from "store/services/adminAPI";

const CreateBroadcast = ({ createBroadcast, setCreate, messageClass }) => {


  const [selectedStatus, setStatus] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectedCohorts, setCohorts] = useState([]);
  const defaultProgram = ProgramOptions.find((option) => option.value === "3mtt");
  const [selectedProgram, setProgram] = useState(defaultProgram);

  const [createNewBroadcast, { isLoading }] = useCreateNewBroadcastMutation();
  const {
    data:cohorts,
    isLoading:loadingCohorts,
    isFetching:fetchingCohorts
  } = useGetCohortsQuery(`program=${selectedProgram?.value}`);

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitted(true);

    if (selectedStatus.length > 0) {
      setSubmitted(false);
      await createNewBroadcast({
        ...values,
        message_type: "info",
        message_class: messageClass,
        status: selectedStatus.map((item) => item.value).join(","),
        recipient_type: values?.recipient_type?.toUpperCase(),
        cohorts: [...selectedCohorts?.map((item) => item.value)] ?? [],
        program: selectedProgram?.value,
      })
        .unwrap()
        .then((data) => {
          toast.success(`✔️ ${data?.message} !`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
          });

          resetForm();
          setCreate(false);
        })
        .catch((error) => {
          Swal.fire({
            title:
              replaceUnderscoresWithSpaces(error?.data?.code) ||
              "Error Occured",
            text: error?.data?.detail,
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Try Again",
          });
        });
    } else {
      toast.error("❌ Please select relevant status!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      message_title: "",
      message_body: "",
      recipient_type: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const CohortOptions =
    cohorts?.data?.cohorts?.map((cohort) => ({
      value: cohort?.rank,
      label: `Cohort ${cohort?.rank}`,
    })) || [];

  return (
    <Modal className="modal fade" show={createBroadcast}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create new {messageClass}</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Select Program</strong>
                  </label>

                  <Select
                    value={selectedProgram}
                    onChange={setProgram}
                    options={ProgramOptions}
                    styles={Style}
                  />

                  {formik.touched.program &&
                  formik.errors.program ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.program}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Title</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="message_title"
                    name="message_title"
                    placeholder="Title"
                    {...formik.getFieldProps("message_title")}
                  />

                  {formik.touched.message_title &&
                  formik.errors.message_title ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.message_title}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Recipient</strong>
                  </label>

                  <select
                    id="recipient_type"
                    name="recipient_type"
                    className="form-control"
                    {...formik.getFieldProps("recipient_type")}
                  >
                    <option value="">Choose Recipient</option>
                    {recipients.map((type) => {
                      return (
                        <option value={type}>
                          {capitalize(type === "provider" ? "ALC" : type)}
                        </option>
                      );
                    })}
                  </select>

                  {formik.touched.recipient_type &&
                  formik.errors.recipient_type ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.recipient_type}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label>Status</label>
                  {/* <Select
                    value={selectedStatus}
                    onChange={setStatus}
                    options={
                      formik.values.recipient_type === "provider"
                        ? providerStatus
                        : formik.values.recipient_type === "fellow"
                        ? fellowNotificationStatus
                        : []
                    }
                    styles={Style}
                    isMulti
                  /> */}

                  <Select
                    value={selectedStatus}
                    onChange={setStatus}
                    options={
                      formik.values.recipient_type === "provider"
                        ? providerStatus
                        : formik.values.recipient_type === "fellow"
                        ? selectedProgram?.value === "deeptech"
                          ? DeeptechFellowStatusOptions
                          : fellowNotificationStatus
                        : []
                    }
                    styles={Style}
                    isMulti
                  />

                  {submitted && selectedStatus.length < 1 ? (
                    <div className="text-danger mt-2 fs-12">
                      Please select relevant status!
                    </div>
                  ) : null}
                </div>
              </div>

              {formik?.values?.recipient_type === "fellow" &&
                selectedStatus?.length > 0 &&
                (selectedStatus.some(
                  (item) =>
                    item.value === "New" ||
                    item.value === "application_completed"
                ) ? null : (
                  loadingCohorts || fetchingCohorts ? (
                    <p>Loading cohorts</p>
                  ) : (
                  <Col md={12}>
                    <p className="p-1 m-0">Select Cohort</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCohorts}
                        onChange={setCohorts}
                        options={CohortOptions}
                        styles={Style}
                        isMulti
                      />
                    </div>
                  </Col>
                  )
                ))}

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Content</strong>
                  </label>

                  <textarea
                    className="w-100 form-control"
                    placeholder="Your answer"
                    id="message_body"
                    name="message_body"
                    {...formik.getFieldProps("message_body")}
                    rows="3"
                  ></textarea>

                  {formik.touched.message_body && formik.errors.message_body ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.message_body} 
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create ${capitalize(messageClass)}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBroadcast;

const FormSchema = Yup.object().shape({
  message_title: Yup.string().required("Title is required"),
  message_body: Yup.string().required("Message body is required"),
  recipient_type: Yup.string().required("Recipient type is required"),
});
