/** @format */
import React, { useRef, useState, useEffect, useMemo} from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { FadeLoader } from "react-spinners";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { ReactComponent as SearchIcon } from "images/svg/search.svg";
import { ReactComponent as FilterIcon } from "images/svg/filter.svg";
import { ReactComponent as GridViewIcon } from "images/svg/grid.svg";
import { ReactComponent as ChartViewIcon } from "images/svg/chart.svg";
import { ReactComponent as CalendarIcon } from "images/svg/calendar-l.svg";
import { COLUMNS } from "./helpers/peerColumns";
// import "../../styles/index.css"
// import CategoryDropdown from "../categoryfilter";
import LeaderboardFilterModal from "../LeaderBoardFilterModal";
import FeatureUnavailableModal from "../FeatureUnavailable";
import { extractFilterValues } from "utils/functions";

const AdminPeerToPeerTable = ({
  P2Pdata,
  metadata,
  isLoading,
  setFilterString,
  setActiveRecord
}) => {
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");

  const [isDropdownVisible] = useState(false);
  const gridIconRef = useRef(null);

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const filterIconRef = useRef(null);

    const [isUnavailableModalVisible, setIsUnavailableModalVisible] = useState(false)

  const toggleUnaivalableModal = () => {
    setIsUnavailableModalVisible(!isUnavailableModalVisible);
  };

  useEffect(() => {
      if (filter) {
        const values = extractFilterValues(filter);
        const filterString = `page=1${
          values?.category ? `&category=${values?.category}` : ""
        }${
          values?.gender ? `&gender=${values?.gender}` : ""
        }${
          values?.state?.length > 0
            ? `&state=${values?.state?.join(",")}`
            : ""
        }${
          values?.lga?.length > 0
            ? `&lga=${values?.lga?.join(",")}`
            : ""
        }${
          values?.course?.length > 0
            ? `&course=${values?.course?.join(",")}`
            : ""
        }${
          values?.min_score ? `&min_score=${values?.min_score}` : ""
        }${
          values?.max_score ? `&max_score=${values?.max_score}` : ""
        }`;
        setFilterString(filterString);
      }
    }, [filter, setFilterString]);


  const toggleFilterModal = () => {
    setIsFilterModalVisible(!isFilterModalVisible);
  };


  
  useEffect(() => {
    setData(P2Pdata);
  }, [isLoading, P2Pdata]);

  const columns = useMemo(
    () => COLUMNS(setActiveRecord),
    [setActiveRecord]
  )


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    // pageOptions,
    gotoPage,
    pageCount,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // const authUserGroupId =  auth_user_group?.id
  const authUserGroupId =  false

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    setFilterString(`?page=&search=${searchTerm}`)
  }
  return (
    <div>
      <div className="leaderboard-card">
        <div className="leaderboard-card-body">
          <div className="leaderboard-header">
            <div className="filter-controls">
              <button 
              className={`filter-btn ${isDropdownVisible ? 'active' : ''}`} 
              onClick={()=>toggleUnaivalableModal()}
              ref={gridIconRef}>
                <GridViewIcon />

              </button>
              <button className="view-toggle-btn" onClick={()=>toggleUnaivalableModal()}>
                <ChartViewIcon />
              </button>
              {/* <CategoryDropdown isVisible={isDropdownVisible} position={getDropdownPosition()} /> */}
            </div>
            
            <div className="search-wrapper">
              <div className="search-icon">
                <SearchIcon />
              </div>
              <input
                type="text"
                className="search-input"
                placeholder="Search by group name or course"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              <div className="search-count">
                
              </div>
            </div>
            
            <div className="filter-controls">
              <button 
                className={`filter-btn ${isFilterModalVisible ? 'active' : ''}`} 
                onClick={()=>toggleFilterModal()} 
                ref={filterIconRef}
              >
                <FilterIcon />
              </button>
              <button className="filter-btn" onClick={()=>toggleUnaivalableModal()}>
                <CalendarIcon />
              </button>
            </div>
          </div>
          <LeaderboardFilterModal 
          isVisible={isFilterModalVisible} 
          setIsFilterModalVisible = {setIsFilterModalVisible}
          setFilter={setFilter}
          setFilterString={setFilterString}
          setActiveRecord={setActiveRecord}
          />
          <FeatureUnavailableModal 
            isVisible={isUnavailableModalVisible} 
            setIsVisible = {setIsUnavailableModalVisible}
          />


          {isLoading ? (
            <div className="loading-container">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <div>
              {data?.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table
                      {...getTableProps()}
                      className="leaderboard-table"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className="leaderboard-header-cell"
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          const isCurrentUser = row.original.id === authUserGroupId;
                          return (
                            <tr 
                              {...row.getRowProps()}
                              className={`leaderboard-row ${isCurrentUser ? 'current-user-row' : ''}`}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td 
                                    {...cell.getCellProps()}
                                    className="leaderboard-cell"
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="pagination-container">
                      <button
                        className="pagination-btn"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        &laquo;
                      </button>
                      <button
                        className="pagination-btn"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        &lt;
                      </button>
                      
                      {[...Array(Math.min(pageCount, 10))].map((_, i) => (
                        <button
                          key={i}
                          className={`pagination-btn ${pageIndex === i ? 'pagination-btn-active' : ''}`}
                          onClick={() => gotoPage(i)}
                        >
                          {i + 1}
                        </button>
                      ))}
                      
                      {pageCount > 10 && (
                        <span className="pagination-ellipsis">...</span>
                      )}
                      
                      {pageCount > 10 && (
                        <button
                          className="pagination-btn"
                          onClick={() => gotoPage(pageCount - 1)}
                        >
                          {pageCount}
                        </button>
                      )}
                      
                      <button
                        className="pagination-btn"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        &gt;
                      </button>
                      <button
                        className="pagination-btn"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        &raquo;
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="empty-state">
                  <BagIcon />
                  <p className="empty-state-message">
                    No peer to peer data available yet.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPeerToPeerTable;