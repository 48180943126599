/** @format */

import React, { Fragment, useState } from "react";
import { FadeLoader } from "react-spinners";
import AdminLayout from "views/admin/Admin";
import AdminLeaderboardTable from "./components/tables/AdminLeaderboardTable";
import PeerGroupStats from "./components/PeerGroupStats";
import LeaderboardTabs from "./components/LeaderboardTabs";
import PeerGroupTracker from "./components/PeerGroupTracker";
import DareyOverviewStats from "./components/DareyOverviewStats";
import LeaderUserTracker from "./components/LeaderUserTracker";
import AdminPeerToPeerTable from "./components/tables/AdminPeerToPeerTable";
import { useGetAdminDareyStatsQuery, useGetAdminFellowLeaderboardQuery, useGetAdminFellowLeaderboardPeertoPeerQuery, useGetAdminFellowLeaderboardPeertoPeerStatsQuery } from "store/services/adminAPI";


const AdminLeaderboardPage = () => {
  const [activeTab, setActiveTab] = useState("Fellows");
  const [filterString, setFilterString] = useState("");
  const [peersFilterString, setPeersFilterString] = useState("");
  const [activeRecord, setActiveRecord] = useState(null);
  

  const {
    isLoading: loadingFellows, isFetching: fetchingFellows,data: leaderboardData} = useGetAdminFellowLeaderboardQuery(
      filterString,
      { skip: activeTab !== "Fellows" }
  );
  const {
    isLoading: loadingP2P, isFetching: fetchingP2P,data: P2PData} = useGetAdminFellowLeaderboardPeertoPeerQuery(
      peersFilterString,
      { skip: activeTab !== "Peer" }
  );
  const {isLoading: loadingStats, data: dareyStats} = useGetAdminDareyStatsQuery();
  const {isLoading: loadingPeerStats, data: dareyPeerStats} = useGetAdminFellowLeaderboardPeertoPeerStatsQuery();

  const isCurrentTabLoading = 
    (activeTab === "Fellows" && (loadingFellows || fetchingFellows)) ||
    (activeTab === "Peer" && (loadingP2P || fetchingP2P));

  return (
    <Fragment>
      <AdminLayout>
        <LeaderboardTabs activeTab={activeTab} setActiveTab={setActiveTab} setActiveRecord={setActiveRecord}/>
        
        {isCurrentTabLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-xl-8">
              {activeTab === "Fellows" && (
                <div>
                  {
                    loadingStats ? null : (
                      <DareyOverviewStats data={dareyStats?.data}/>
                    )
                  }
                  <AdminLeaderboardTable 
                    leaderboardData={leaderboardData?.data?.results}
                    metadata={leaderboardData?.data?.metadata}
                    auth_user_row={leaderboardData?.data?.auth_user_row}
                    isLoading={isCurrentTabLoading}
                    setFilterString={setFilterString}
                    setActiveRecord={setActiveRecord}
                  />
                </div>
              )}
              
              {activeTab === "Peer" && (
                <div>
                   {
                    loadingPeerStats ? null : (
                      <PeerGroupStats 
                    data={dareyPeerStats?.data}
                  />
                    )
                  }
                  <AdminPeerToPeerTable 
                    P2Pdata={P2PData?.data?.results}
                    metadata={P2PData?.data?.metadata}
                    auth_user_group={P2PData?.data?.auth_user_group}
                    isLoading={isCurrentTabLoading}
                    setFilterString={setPeersFilterString}
                    setActiveRecord={setActiveRecord}
                  />
                </div>
              )}
            </div>
            
            <div className="col-12 col-xl-4">
              {
                activeTab === "Peer" ? (
                  <PeerGroupTracker activeRecord={activeRecord} />
                ) : (
                  <LeaderUserTracker activeRecord={activeRecord} />
                )
              }
            </div>
          </div>
        )}
      </AdminLayout>
    </Fragment>
  );
};

export default AdminLeaderboardPage;