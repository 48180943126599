/** @format */
import React from "react";
import { ReactComponent as BgIcon } from "images/svg/bg.svg";
import { ReactComponent as PeerGroupScore } from "images/svg/peer-group-score.svg";
import { ReactComponent as DareyGroupIcon } from "images/svg/darey-people.svg";
import { ReactComponent as ProjectStatsIcon } from "images/svg/project-stats.svg";




const DareyOverviewStats = ({ data }) => {
  return (
    <div className="leader-cards">
      <div className="stat-card">
        <PeerGroupScore />
        <h3 className="stat-title">Highest Darey <br/>Score</h3>
        <h3 className="stat-count">{Math.round(data?.highest_darey_score)*10}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>

      <div className="stat-card">
        <DareyGroupIcon />
        <h3 className="stat-title">Total Leaderboard<br/> Fellows</h3>
        <h3 className="stat-count">{data?.total_number_of_fellows}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      <div className="stat-card">
        <DareyGroupIcon />
        <h3 className="stat-title">Number of Inactive <br/>Fellows</h3>
        <h3 className="stat-count">{data?.number_of_inactive_fellows}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      <div className="stat-card">
        <ProjectStatsIcon />
        <h3 className="stat-title">Project Submission <br/>Statistics</h3>
        <h3 className="stat-count">N/A</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
    </div>
  );
};

export default DareyOverviewStats;
