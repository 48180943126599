/** @format */

import { Dropdown, Modal, Button } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, { useState, useEffect } from "react";
import {
  capitalize,
  mapStatusClass,
  truncateString,
  mapStatusHeading,
} from "utils/strings";
import { replaceSpace } from "utils/strings";
import Select from "react-select";
import { Nigerian_States, LocalGov } from "utils/constant.data";
import { Style } from "utils/select";
import { useUpdateFellowProfileMutation } from "store/services/adminAPI";
import { toast } from "react-toastify";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";
import ChangeEmailModal from "./ChangeEmailModal";
import { useGetAllCoursesQuery } from "store/services/notification";

export const COLUMNS = (selectedIds, handleRowSelection) => [
  {
    Header: "",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <input
          type="checkbox"
          checked={selectedIds.includes(row?.original?.id) ? true : false}
          onChange={() =>
            handleRowSelection(row?.original?.id, row?.original?.fellow_id)
          }
        />
      );
    },
  },
  {
    Header: "Name",
    accessor: "name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(
        truncateString(
          `${cell?.row?.original?.first_name} ${cell?.row?.original?.last_name}`,
          15
        )
      )} `;
    },
  },
  {
    Header: "Email",
    accessor: "email",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.email, 15))} `;
    },
  },

  {
    Header: "Fellow ID",
    accessor: "fellow_id",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return cell?.row?.original?.fellow_id
        ? `${capitalize(cell?.row?.original?.fellow_id)} `
        : "No Provider";
    },
  },

  {
    Header: "ALC",
    accessor: "provider",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      let deeptech_profile = cell?.row?.original?.deeptech_profile;
      const provider = deeptech_profile
        ?  deeptech_profile?.provider?.name
        :  cell?.row?.original?.provider?.name;
      return provider ? provider : "Unassigned";
    },
  },

  {
    Header: "Program",
    accessor: "current_program",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return capitalize(cell?.row?.original?.current_program);
    },
  },

  {
    Header: "Gender",
    accessor: "gender",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },
  {
    Header: "Cohort",
    accessor: "cohort",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      let deeptech_profile = cell?.row?.original?.deeptech_profile;
      const _cohort = deeptech_profile
        ?  deeptech_profile?.cohort
        :  cell?.row?.original?.cohort;
      return _cohort ? `Cohort ${_cohort?.rank}` : "Unassigned";
    },
  },

  {
    Header: "Course",
    accessor: "fellow_course",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      let deeptech_profile = cell?.row?.original?.deeptech_profile;
      if (deeptech_profile) {
        return deeptech_profile?.course
          ? deeptech_profile?.course?.title
          : "Unassigned";
      } else {
        return cell?.row?.original?.fellow_course
          ? cell?.row?.original?.fellow_course?.title
          : cell?.row?.original?.current_learning_course || "Unassigned";
      }
    },
  },

  {
    Header: "Progress",
    accessor: "training_progress",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      if (value === null) {
        return "0%";
      } else {
        return `${value}%`;
      }
    },
  },
  {
    Header: "Level",
    accessor: "proficiency_level",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      const _proficiency_level = cell?.row?.original?.proficiency_level ?? null;
      return _proficiency_level ? _proficiency_level : "Not Set";
    },
  },
  {
    Header: "Rank",
    accessor: "proficiency_rating",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      const _proficiency_rating =
        cell?.row?.original?.proficiency_rating ?? null;
      return _proficiency_rating ? _proficiency_rating : "Not Rated";
    },
  },

  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Filter: ColumnFilter,
    Cell: ({ cell }) => {
      let status_value = cell?.row?.original?.status;
      let deeptech_profile = cell?.row?.original?.deeptech_profile;
      const _class = mapStatusClass(
        replaceSpace(deeptech_profile ? deeptech_profile?.status : status_value)
      );
      return (
        <span className={`badge light badge-${_class}`}>
          <i className={`fa fa-circle text-${_class} me-1`} />
          {deeptech_profile
            ? `D-${capitalize(mapStatusHeading(deeptech_profile?.status))}`
            : capitalize(mapStatusHeading(status_value))}
        </span>
      );
    },
  },

  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const fellow_course_id = cell?.row?.original?.fellow_course?.id;
      const getFellowCurrentCourse = () => {
        let deeptech_profile = cell?.row?.original?.deeptech_profile;
        if (deeptech_profile) {
          return deeptech_profile?.course ? deeptech_profile?.course?.title  : 'Unassigned';
        } else {
          return (cell?.row?.original?.fellow_course)
          ? cell?.row?.original?.fellow_course?.title
          : cell?.row?.original?.current_learning_course || 'Unassigned';
      }
      };
      const {
        id,
        first_name,
        last_name,
        email,
        phone,
        residence,
        fellow_id,
        address,
        lga,
        current_program
      } = cell?.row?.original;
      const [allowEdit] = useFeatureCheck(Checks.update_fellow_profile);
      const [allowFellowEmailChange] = useFeatureCheck(
        Checks.update_update_fellow_email
      );
      const [showProfile, setShowProfile] = useState(false);
      const [editMode, setEditMode] = useState(false);
      // const [newState, setNewState] = useState(residence);
      // const [newLGA, setNewLGA] = useState(lga);
      // const [newPhone, setNewPhone] = useState(phone);
      // const [newFirstName, setNewFirstName] = useState(first_name);
      // const [newLastName, setNewLastName] = useState(last_name);
      // const [newAddress, setNewAddress] = useState(address);
      // const [newCourse, setNewCourse] = useState(fellow_course_id)
      const [showEmailChangelModal, setShowEmailChangeModal] = useState(false);

      const [newState, setNewState] = useState(residence ?? "");
      const [newLGA, setNewLGA] = useState(lga ?? "");
      const [newPhone, setNewPhone] = useState(phone ?? "");
      const [newFirstName, setNewFirstName] = useState(first_name ?? "");
      const [newLastName, setNewLastName] = useState(last_name ?? "");
      const [newAddress, setNewAddress] = useState(address ?? "");
      const [newCourse, setNewCourse] = useState(fellow_course_id ?? "");

      const hideEmailChangeModal = () => {
        setShowEmailChangeModal(false)
      }

      const { data:coursesList, isLoading: loadingCourses, isFetching: fetchingCourses } = useGetAllCoursesQuery(`program=${current_program}`);
      
      const [updateFellowProfile, { isLoading }] =
        useUpdateFellowProfileMutation();

      // useEffect(()=>{
      //   setNewState(residence)
      //   setNewFirstName(first_name)
      //   setNewLastName(last_name)
      //   setNewAddress(address)
      //   setNewPhone(phone)
      //   setNewLGA(lga)
      //   setNewCourse(fellow_course_id)
      // }, [residence,first_name,last_name,address,phone,lga, fellow_course_id])

      useEffect(() => {
        setNewState(residence ?? "");
        setNewFirstName(first_name ?? "");
        setNewLastName(last_name ?? "");
        setNewAddress(address ?? "");
        setNewPhone(phone ?? "");
        setNewLGA(lga ?? "");
        setNewCourse(fellow_course_id ?? "");
      }, [
        residence,
        first_name,
        last_name,
        address,
        phone,
        lga,
        fellow_course_id,
      ]);

      const StateOptions = Nigerian_States.map((state) => ({
        value: state,
        label: state,
      }));

      const getLGData = (newState) => {
        const stateData = LocalGov.find(
          (stateObj) => stateObj.state.toLowerCase() === newState.toLowerCase()
        );

        return stateData
          ? stateData.lgas.map((lga) => ({ value: lga, label: lga }))
          : [];
      };


    const CoursesOptions = coursesList?.data?.courses
      ?.filter((course) => course?.status)
      ?.map((course) => ({
        value: course?.id,
        label: course?.title,
        code: course?.code,
      })) || [];

      const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedFields = {};

        if (newState !== cell?.row?.original?.residence) {
          updatedFields.residence = newState;
        }

        if (newLGA !== cell?.row?.original?.lga) {
          updatedFields.lga = newLGA;
        }

        if (newPhone !== cell?.row?.original?.phone) {
          updatedFields.phone = newPhone;
        }

        if (newFirstName !== cell?.row?.original?.first_name) {
          updatedFields.first_name = newFirstName;
        }

        if (newLastName !== cell?.row?.original?.last_name) {
          updatedFields.last_name = newLastName;
        }

        if (newAddress !== cell?.row?.original?.address) {
          updatedFields.address = newAddress;
        }

        if (newCourse !== fellow_course_id) {
          updatedFields.fellow_course_id = newCourse;
        }

        // if (newCourse !== cell?.row?.original?.address) {
        //   updatedFields.address = newAddress;
        // }

        Object.keys(updatedFields).forEach((key) => {
          if (updatedFields[key] === "") {
            delete updatedFields[key];
          }
        });

        if (Object.keys(updatedFields).length === 0) {
          toast.error(
            "❌ Please update at least one field before submitting.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
          return;
        }

        try {
          await updateFellowProfile({
            id: cell?.row?.original?.id,
            ...updatedFields,
          });

          toast.success(`✔️ Profile updated successfully!`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
          });

          setTimeout(() => {
            setShowProfile(false);
          }, 1000);
        } catch (error) {
          toast.error("❌ Could not update profile, an error occured;", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      };

      return (
        <>
          {/* ProfileModal */}
          <ChangeEmailModal
            showEmailChangelModal={showEmailChangelModal}
            hideEmailChangeModal={hideEmailChangeModal}
            fellowId={id}
          />
          <Modal
            show={showProfile}
            onHide={() => setShowProfile(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Fellow Details</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                {editMode ? (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group mb-3">
                        <label>First Name</label>
                        <input
                          placeholder="Change first name"
                          // value={newFirstName}
                          defaultValue={first_name}
                          type="text"
                          className="form-control bg-transparent"
                          id="new_first_name"
                          name="new_first_name"
                          onChange={(e) => setNewFirstName(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label>Last Name</label>
                        <input
                          placeholder="Change last name"
                          value={newLastName}
                          type="text"
                          className="form-control bg-transparent"
                          id="new_last_name"
                          name="new_last_name"
                          onChange={(e) => setNewLastName(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label>Address</label>
                        <input
                          type="text"
                          placeholder="Change Address"
                          value={newAddress}
                          className="form-control bg-transparent"
                          id="new_address"
                          name="new_address"
                          onChange={(e) => setNewAddress(e.target.value)}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label>State</label>
                        <Select
                          value={newState}
                          onChange={(selectedOption) =>
                            setNewState(selectedOption.value)
                          }
                          options={StateOptions}
                          styles={Style}
                          placeholder={newState}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label>LGA</label>
                        <Select
                          value={newLGA}
                          onChange={(selectedOption) =>
                            setNewLGA(selectedOption.value)
                          }
                          options={getLGData(newState)}
                          styles={Style}
                          placeholder={newLGA}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label>Phone</label>
                        <input
                          type="text"
                          placeholder="Change Phone"
                          value={newPhone}
                          className="form-control bg-transparent"
                          id="fellow_phone"
                          name="fellow_phone"
                          onChange={(e) => setNewPhone(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        {!cell?.row?.original?.fellow_course && (
                          <>
                            <p>
                              Current course:{" "}
                              <span className="bold">
                                {getFellowCurrentCourse()}
                              </span>
                            </p>
                          </>
                        )}
                        <label>Change Course</label>
                        {
                          loadingCourses || fetchingCourses ? (
                            <p>Loading courses...</p>

                          ) : (
                            <Select
                            value={CoursesOptions.find(option => option.value === newCourse)}
                            onChange={(selectedOption) => setNewCourse(selectedOption.value)}
                            options={CoursesOptions}
                            styles={Style}
                            placeholder="Select a course"
                          />
                          )
                        }
                       
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-center align-items-center gap-1">
                      <Button
                        variant="primary"
                        onClick={(e) => setEditMode(false)}
                      >
                        Discard
                      </Button>
                      <Button
                        variant="outline"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {isLoading ? (
                          <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm ms-2"
                          ></span>
                        ) : (
                          ` Update`
                        )}
                      </Button>
                    </div>
                  </form>
                ) : (
                  <div className="col-xl-12">
                    <div className="card  course-dedails-bx">
                      <div className="card-header border-0 pb-0">
                        <h5>
                          {capitalize(first_name)} {capitalize(last_name)} (
                          {fellow_id})
                        </h5>
                      </div>
                      <div className="card-body pt-0">
                        <div className="description">
                          <p>{capitalize(email)}</p>
                          <ul className="d-flex align-items-center raiting flex-wrap">
                            <li>
                              <span className="font-w500">Number: {phone}</span>
                            </li>
                          </ul>
                          <div className="user-pic mb-3">
                            <span>
                              State of Residence: {capitalize(residence)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!editMode && (
                  <div className="d-flex w-100 justify-content-center align-items-center gap-1">
                    <Button
                      variant="primary"
                      onClick={(e) => setShowProfile(false)}
                    >
                      Close
                    </Button>

                    {allowEdit && (
                      <Button
                        variant="outline"
                        onClick={(e) => setEditMode(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Modal>
          {/* Profile Modal */}

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowProfile(true)}>
                View Profile
              </Dropdown.Item>
              {allowFellowEmailChange && (
                <Dropdown.Item onClick={() => setShowEmailChangeModal(true)}>
                  Change Email
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];
