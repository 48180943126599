export const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #7117E5" : "1px solid #BCC1DE",
      borderRadius: "4px",
      padding: "5px",
      transition: "border-color 0.2s ease",
      maxWidth: "178.5px",
      
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "4px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#f0f0f0"
        : state.isSelected
        ? "red"
        : "white",
      color: state.isSelected ? "white" : "#333",
      cursor: "pointer",
      padding: "10px",
    }),
    placeholder: (provided) => ({
      ...provided,
    //   color: "#888",
      color: "fff000"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",
    }),
  };